<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-03-09 17:12:51
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-23 21:42:19
 -->
<template>
  <div>
    <div class="topimgbg">
      <img src="@/assets/nh/nhwx-001.jpg" alt class="mainpic" />

      <div class="contentbox">
        <div
          class="article"
          v-for="(item,index) in datalist"
          :key="index"
          @click="tiaozhuan(item,index)"
        >
          <div class="left">
            <div class="toptips" v-if="item.typenamelist!=''">
              <p class="cpfl">{{item.typenamelist[0]}}</p>
            </div>
            <div class="tpkuang">
              <img :src="ossurl + item.iconhttp" alt />
            </div>
            <p class="gongsi">{{item.shortname}}</p>
          </div>
          <div class="right">
            <p class="title">{{item.prodname}}</p>
            <div class="baofei">
              <div class="zitiyanse" v-if="item.minprice">
                <span class="rmb" :style="`color: ${colorprimary};`">￥</span>
                <span class="shuzi" :style="`color: ${colorprimary};`">{{item.minprice}}</span>起
              </div>
            </div>
            <p class="article-content">{{item.pdesc}}</p>
          </div>
        </div>
      </div>
      <div v-if="datalist.length=='0'" style="margin-top: 3rem;">
        <img src="@/assets/nh/nhwxtynull.jpg" alt />
        <p class="asdasdqwe">木有产品 添加试试~</p>
      </div>
    </div>
    <div class="dibu" v-if="empno==user.empno">
      <div class="leftbianji" @click="chanpinye" :style="`backgroundColor: ${colorprimary};`">
        <span class="leftziti">编辑微店</span>
      </div>
    </div>
    <div class="fenxiang" v-if="empno!=user.empno">
      <div class="leftcall">
        <img src="@/assets/nh/nhwxdh.png" alt class="callimg" />
        <span class="callname">
          {{contact.cardempname}}
          {{contact.cardmobile}}
        </span>
      </div>
      <div class="rightcall" :style="`backgroundColor: ${colorprimary};`">
        <a :href="'tel:' + contact.cardmobile">
          <span class="call">打电话</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { showListsd } from "@/api/agent/agent/index";
import { getStorage } from "@/lib/util";
import { wechatshare } from "@/lib/wechat_share";
import config from "@/config";
import initWebSocket from "@/mixins/websock";
import { DoRecord } from "@/api/abt/customerOperation/common/index";
const { redirect_uri } = config;
export default {
  mixins: [initWebSocket],
  data() {
    return {
      colorprimary: "",
      websock: null,
      bjwdanniu: true,
      ossurl: "",
      datalist: [],
      height: "",
      rytype: "",
      empno: "",
      empname: "",
      isshare: "",
      contact: ""
    };
  },
  created() {
    this.colorprimary = getStorage("theme", "");
    this.ossurl = getStorage("ossurl", "");
    this.user = getStorage("u_s", {});
    // this.height = document.body.clientHeight - 195
    this.isshare = this.$route.query.isshare ? this.$route.query.isshare : "";
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype;
    this.empno = this.$route.query.empno
      ? this.$route.query.empno
      : this.user.empno;

    this.empname = this.$route.query.empname
      ? this.$route.query.empname
      : this.user.empname;
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid;
    this.getList();
    this.ylbehaviorRecord();
  },
  mounted() {
    // document.querySelector('.contentbox').style.height = `${this.height}px`
  },
  methods: {
    tiaozhuan(item, index) {
      if (item.tbflag == "0") {
        this.behaviorRecord(item);
        // location.href = item.comtburl
      }
      if (item.tbflag == "1") {
        this.$router.push(
          `/productShowNhwx?jumppath=${item.showhttp}&iconhttp=${item.iconhttp}&prodname=${item.prodname}&pdesc=${item.pdesc}&prodcode=${item.prodcode}&empno=${this.empno}&suserid=${this.suserid}&rytype=${this.rytype}&isshare=${this.isshare}`
        );
      }
    },
    // 行为记录
    behaviorRecord(val) {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        sno: val.prodcode,
        otype: "1",
        btagcode: "7",
        rytype: this.rytype
      };
      DoRecord(data).then(res => {
        let id = res.data.data.id;
        let comid = this.user.comid;
        this.initWebSocket(id, comid); // 开启websocket连接
        location.href = val.comtburl;
        // alert(id)
      });
    },
    // 微信分享
    wxshare() {
      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        rytype: this.rytype,
        otype: "2",
        btagcode: "17"
      };

      let path = "";
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        if (this.$route.query.frompage == "source") {
          path = config.redirect_uri + "/source";
        } else {
          path = config.redirect_uri;
        }
      } else {
        // android
        path = window.location.href;
      }
      let shareurl = `${config.redirect_uri}/microshop?suserid=${this.user.userid}&empno=${this.empno}&empname=${this.empname}&rytype=${this.rytype}&isshare=true`;
      wechatshare(
        `${this.empname}的微店`,
        `一份保障，守护全家`,
        this.user.headimg,
        encodeURI(shareurl),
        path,
        fxstr
      );
    },
    getList() {
      let data = {
        baseid: "",
        prodtype: "",
        search: "",
        type: "1",
        empno: this.empno
      };
      showListsd(data).then(res => {
        this.contact = res.data.data;
        this.datalist = res.data.data.list;
        this.$nextTick(() => {
          this.wxshare();
        });
      });
    },

    // 预览的行为记录
    ylbehaviorRecord() {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: "1",
        btagcode: "17",
        rytype: this.rytype
      };
      DoRecord(data).then(res => {
        let id = res.data.data.id;
        let comid = this.user.comid;
        this.initWebSocket(id, comid); // 开启websocket连接
      });
    },
    chanpinye() {
      this.$router.push(`/management?`);
    }
  }
};
</script>

<style scoped lang="stylus">
.topimgbg {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10vh;
  background-color: rgb(255, 255, 255);
}

.mainpic {
  height: auto;
}

.contentbox {
  padding: 0.5rem 0.3rem 0;
}

.article {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  height: 3.1rem;
}

.left {
  width: 27%;
  margin-left: 0.2rem;
  position: relative;
  box-shadow: 7px 7px 10px #fcfafa;
  border-radius: 0.15rem;
}

.contentbox .article img {
  width: 100%;
  height: 2.3rem;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
}

.right {
  width: 66%;
}

.title {
  height: 0.6rem;
  padding: 0 0 0.1rem 0;
  /* font-weight: bold; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0.2rem;
  color: #383838;
  font-family: PingFang-SC-Bold;
}

.article-content {
  background: #f7fbff;
  color: #989898;
  font-size: 0.3rem;
  margin-bottom: 0.1rem;
  margin-top: 0.2rem;
  height: 1.1rem;
  line-height: 1.7;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 0.1rem;
  padding-top: 0.1rem;
  padding-left: 0.2rem;
  padding-right: 0.1rem;
}

.baofei {
  height: 0.6rem;
  display: flex;
  margin-top: 0.2rem;
}

.shuzi {
  font-weight: 600;
  margin-right: 0.1rem;
  // color: color-primary;
  font-family: OPPOSans-B;
  font-size: 0.35rem;
}

.rmb {
  // color: color-primary;
  font-family: OPPOSans-B;
  font-size: 0.3rem;
}

.zitiyanse {
  color: #989898;
  font-size: 0.3rem;
}

.dibu {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  display: flex;
  width: 100%;
  height: 1.2rem;
}

.leftbianji {
  width: 100%;
  // background-color: color-primary;
  padding: 10px 40px;
  display: flex;
  align-items: center;
}

.leftziti {
  margin: auto;
  color: rgb(255, 255, 255);
}

.gongsi {
  text-align: center;
  font-size: 0.3rem;
  margin-top: 0.2rem;
  color: #989898;
}

.toptips {
  background-image: url('~@/assets/nh/nhwxmd-01.png');
  width: 72%;
  height: 0.9rem;
  background-size: 1.6rem 0.55rem;
  background-repeat: no-repeat;
  position: absolute;
  right: 0.7rem;
  line-height: 0.6rem;
}

.cpfl {
  font-size: 0.3rem;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  width: 1.3rem;
  margin-left: 0.2rem;
}

.tpkuang {
  width: 100%;
  height: 2.3rem;
}

.asdasdqwe {
  text-align: center;
  color: #c4c4c4;
}

.fenxiang {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  display: flex;
  width: 100%;
  height: 1.2rem;
  background-color: #ffffff;
  justify-content: space-between;
}

.leftcall {
  padding: 0.1rem 0.4rem;
  display: flex;
  align-items: center;
  width: 65%;
}

.callname {
  /* margin: auto; */
  color: #383838;
  font-size: 0.3rem;
  font-family: PingFang-SC-Bold;
}

.rightcall {
  // background-color: color-primary;
  margin: auto 1.2rem auto 0.5rem;
  width: 1.6rem;
  height: 0.6rem;
  border-radius: 0.5rem;
  text-align: center;
}

.call {
  font-size: 0.35rem;
  color: #ffffff;
  line-height: 2;
}

.callimg {
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 1rem;
  margin-right: 0.1rem;
}
</style>
